import _ from "underscore";
import store from "@/store"

export default class Config {

  static data = null;

  static endpoints = {
    "feedback":"/feedback/form/{slug}",

    "customer":'/customer',
    "resetPassword":"api/password/email",
    "sites":"/sites",
    "site":"/site/{siteId}",
    "tutorials":"/tutorials",
    "tutorial":"/tutorial/{tutorialId}",
    "tours":"/site/{siteId}/tours",
    "tour":"/tour/{tourId}",
    "siteInfo":"/site-info/{siteInfoId}",
    "siteNews":"/site/{siteId}/news",
    "siteGallery":"/site/{siteId}/gallery",
    "news":"/site-news/{newsId}",
    "cardBalance":"/loyalty/balance/{brandId}",
    "cardBalanceSite":"/site/{siteId}/loyalty/balance",
    "cardHistory":"/loyalty/history/{brandId}",
    //"cardRewards":"/loyalty/rewards/{cardType}",
    //"cardCheck":"/loyalty/check/{cardType}/{checkID}",

    'table':'/site/{siteId}/table/{tableId}',

    "packages":"/site/{siteId}/management/packages/",
    "hubPrep":'/management/hub/prepare',
    "pubReceive":'/site/{siteId}/management/pub/receive',



    'menus':'/site/{siteId}/menus/{type}',
    'menu':'/site/{siteId}/menu/{menuId}',
    'newMenuProducts':'/new-menu-products',
    'newMenuProduct':'/site/{siteId}/menu-product/{menuProductId}/new',

    'menuProducts':'/site/{siteId}/menu/{menuId}/menu-products',
    'menuProduct':'/site/{siteId}/menu-product/{menuProductId}',
    'menuItem':'/site/{siteId}/menu-item/{menuItemId}',
    'modifier':'/site/{siteId}/modifier/{modifierId}',
    "siteModifierMods":"/site/{siteId}/modifier/{modifierId}/modifierMods/standard",
    "siteModifierModsPopup":"/site/{siteId}/modifier/{modifierId}/modifierMods/popup",

    "nutritionInfo":"/recipe/{plu}/{type}",
    "recipeByGuid":"/recipe/{guid}",


    "subMenus":"/menu/{menuId}/submenus/{type}",
    "subMenu":"/submenu/{submenuId}",
    "menuItems":"/submenu/{submenuId}/menu-items",
    //"menuItem":"/menu-item/{menuItemId}",
    "salesItem":"/sales-item/{salesItemId}",
    "modifierGroup":"/modifier-group/{modifierGroupId}",
   //"modifier":"/modifier/{modifierId}",
    "modifierMods":"/modifier/{modifierId}/modifierMods",
    //"siteModifierMods":"/site/{siteId}/modifier/{modifierId}/modifierMods",


    'qty':'/site/{siteId}/plu/{POSItemId}/qty',
    'qtys':'/site/{siteId}/qtys',
    "search":"/site/{siteId}/search",
    "image":"/image/{imageId}",

    "orderCreate":"/ticket/create",
    "orderUpdate":"/ticket/{id}/update",
    "orderPay":"/ticket/{uuid}/pay",
    "orderPaid":"/ticket/{uuid}/paid",

    "checkCreate":"/site/{siteId}/check/create",
    "bookingsUpdate":"/site/{siteId}/booking/{bookingUuid}/update",
    "checkUpdate":"/site/{siteId}/check/{uuid}/update",

    "checkGet":"/site/{siteId}/check/{uuid}/get",
    "checkPay":"/check/{uuid}/pay",

    "bookingPaid":"/booking/{uuid}/paid",
    "checkPaid":"/check/{uuid}/paid",
    "checkItem":'/site/{siteId}/check/{uuid}/item',
    "checkItems":'/site/{siteId}/check/{checkId}/items',
    "deleteCheck":'/site/{siteId}/check/{uuid}',
    "deleteCheckItems":'/site/{siteId}/check/{uuid}/items',
    "deleteCheckItem":'/check-item/{checkItemId}',

    "checkPayments":'/site/{siteId}/check/{checkId}/payments',
    'updateCheckTicket':'/check-ticket/{checkTicketId}',

    "userLastOrder":"/user/last-order",
    'bookings':"/bookings",
    "orders":"/orders",
    "order":"/order/{orderId}",
    "orderReceipt":"/order/{orderId}/receipt",
    "orderAloha":"/order/{orderId}/aloha",
    "orderByUuid":"/ticket/{uuid}/uuid",
    "fullPointsPayment":'/ticket/{uuid}/full-points-payment',
    "fullCheckPointsPayment":'/check/{uuid}/full-points-payment',
    "fullBookingPointsPayment":'/booking/{uuid}/full-points-payment',

    "appOnline":"/app-online",
    "appVersion":"/app-version",
    "activeSites":"/active-sites",
    "tncs":"/tncs",
    "userFavorite"       : "/site/{siteId}/user/favorite",
    "createUserFavorite" : "/user/favorite",
    "waitTime"           : "/site/{siteId}/wait-time",
    "confetti"           : "/confetti",
    "address"            : '/address/{postcode}',

    'checks'             : '/site/{siteId}/table/{tableId}/checks',
    'check'              : '/site/{siteId}/check/{checkId}',

    'giftCardDesigns'       : '/giftcard/designs',
    'access'                : '/access/{uuid}',
    'generateDiscount'      : '/discount-code',

    'appytapAction'         : '/site/{siteId}/appytap/{shortcut}/action/{nonce}',
    'appytapTeam'           : '/site/{siteId}/appytap/user-status',
    'appytapManagement'     : '/appytap/management/action',

    'opentablesCreateOrder' : '/opentables/order/create',
    "bookingByUuid"         :  '/booking/{bookingUuid}',

    "hostTables"            :  '/site/{site_id}/management/host-tables/{date}',
    "unnassignedHostTables" :  '/site/{site_id}/management/unnassigned-host-tables',
    'hosts'                 :  '/site/{site_id}/management/hosts',

    'teamHostTables'         :  '/site/{siteId}/management/my-tables',

    'team'                        :  '/site/{siteId}/management/team',
    'one2oneitems'                :  '/management/team/{teamId}/one2oneitems',
    'one2oneGroupItemSetttings'   :  '/management/one2onegroupitems/settings',
    'one2oneGroupItems'   :  '/management/one2onegroupitems',
    'teamProducts'   :  '/management/team-products'

  }

  static noCache = [
      'one2oneGroupItems',
      'teamHostTables',
      'packages',
      'hubPrep',
      'pubReceive',
      'hosts',
      'hostTables',
    'createUserFavorite',
    "cardBalance",
    "cardBalanceSite",
    "cardHistory",
    "cardRewards",
    "cardCheck",
    "checkGet",
    "checkPaid",
    "checkPay",
    "checkPayments",
    'check',
    "payFullOrderWithPoints",
      'fullBookingPointsPayment',
    "userLastOrder",
    "orders",
    "order",
    "search",
    "userFavorite",
    "waitTime",
    "sites",
    'qty',
    'qtys',
    'tncs',
    'checks',
    'check',
    'generateDiscount',
    'appytapTeam',
    'access',
    'appytapAction',
    'one2oneitems'

  ];

  static getEndpointStoreDefaults(){

    let defaults = {}
    _.each(this.endpoints,(v,i)=>{
      defaults[i]={}
    });
    return defaults

  }

  static endpoint(key,vars=null){
    if(vars===null){
      return this.endpoints[key];
    }else{
      let url = this.endpoints[key];
      _.each(vars,(v,i)=>{
        url = url.replace("{"+i+"}",v);
      });
      return url;
    }
  }

  static getConfigOptions(key){
    const config = store.getters.config;
    return _.find(config,(value)=>{
      return value.key===key;
    });
  }

  static getConfig(key,subkey){
    const config = store.getters.config;
    const configRow =  _.find(config,(value)=>{
      return value.key===key;
    });
    if(!configRow){ return null; }
    const found =  _.find(configRow.value,(value)=>{
      return value.key===subkey;
    });
    return (found && found.Value)?found.Value:null;
  }

  static getConfigByKey(key) {
    const config = store.getters.config;
    const configRow = _.find(config, (value) => {
      return value.key === key;
    });
    return (configRow) ? configRow.value : null;
  }


  static getApiBase(){

    let base = '';
    const domain  = window.location.host;

    if(domain.includes('phone2fork')!==true) base = base+(process.env.VUE_APP_API_DOMAIN).toString();

    base = base+(process.env.VUE_APP_API_BASE).toString();

    return base;
  }

  static getImageBase(){
    return (process.env.VUE_APP_STORAGE_BASE).toString();
  }

}